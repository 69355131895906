<template>
  <div>
    <v-row v-if="showAccordionInputs && !config.simpleText">
      <v-col cols="12" sm="12" md="12">
        <h3>{{ $t(config.label) }}</h3>
        {{ $t(config.questionLabel) }}
        <ckeditor
          v-model="inputData.question"
          :config="editorConfigIntro"
        ></ckeditor>
      </v-col>
    </v-row>
    <v-row v-if="showAccordionInputs && !config.simpleText">
      <v-col cols="12" sm="12" md="12">
        {{ $t(config.answerLabel) }}
        <ckeditor
          key="answer"
          v-model="inputData.answer"
          :config="editorConfig1"
        ></ckeditor>
      </v-col>
    </v-row>

    <v-row v-if="showAccordionInputs && config.simpleText">
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          v-model="inputData.question"
          :label="$t(config.questionLabel)"
          @input="handleChangeValue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="showAccordionInputs && config.simpleText">
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          outlined
          :label="$t(config.answerLabel)"
          v-model="inputData.answer"
          @input="handleChangeValue"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="6" md="6">
        <v-btn
          color="primary"
          @click="handleAddInputData"
          :disabled="
            inputData.answer.length == 0 || inputData.question.length == 0
          "
        >
          {{ $t("FORMS.add") }}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-switch
        v-model="showAccordionInputs"
        :label="$t('FORM_INPUT_NAMES.editing_accordion')"
      ></v-switch>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="accordions.length > 0">
      <v-col cols="12">
        <v-expansion-panels focusable>
          <draggable v-model="draggableCards" style="width: 100%">
            <v-expansion-panel v-for="(item, i) in files" :key="item.name">
              <v-expansion-panel-header>
                <div v-html="item.question"></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-html="item.answer"></span>
                <v-spacer></v-spacer>
                <v-icon
                  :disabled="editedItemIndex != null"
                  color="primary"
                  style="
                    display: block;
                    font-size: 16px;
                    float: right;
                    margin-right: 5px;
                  "
                  small
                  @click="deleteItem(i)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  :disabled="editedItemIndex != null"
                  small
                  color="primary"
                  style="
                    cursor: pointer;
                    display: block;
                    font-size: 16px;
                    float: right;
                    margin-right: 5px;
                  "
                  @click="handleEditInputData(i, item)"
                >
                  mdi-pencil
                </v-icon>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
      <DeleteModalDialog
        :dialogDelete="dialogDelete"
        @closeDelete="handleCloseDelete"
        @deleteItemConfirm="handleDeleteItemConfirm"
      >
      </DeleteModalDialog>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

const INITIAL_INPUT_DATA = { question: "", answer: "" };

export default {
  name: "AccordionCustomFieldComponent",
  components: { draggable, DeleteModalDialog },
  props: ["config", "accordions"],
  data() {
    return {
      editorConfig1: {
        htmlEncodeOutput: false,
        entities: false,
        forcePasteAsPlainText: true,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      inputData: Object.assign({}, INITIAL_INPUT_DATA),
      dialogDelete: false,
      itemToDeleteIndex: null,
      editedItemIndex: null,
      files: [],
      showAccordionInputs: false,
    };
  },

  watch: {
    accordions(newValue) {
      this.files = newValue;
    },
  },

  computed: {
    draggableCards: {
      get() {
        // return this.accordions;
        return this.files;
      },
      set(val) {
        this.files = val;
        this.accordions = val;
        this.handleChangeValue();
      },
    },
  },

  methods: {
    handleAddInputData() {
      if (!this.accordions) {
        this.accordions = [];
      }

      if (this.editedItemIndex == null) {
        this.accordions.push(Object.assign({}, this.inputData));
      } else {
        this.accordions[this.editedItemIndex] = Object.assign(
          {},
          this.inputData
        );
      }

      this.files = this.accordions;

      this.inputData = Object.assign({}, INITIAL_INPUT_DATA);

      this.editedItemIndex = null;
      this.handleChangeValue();
    },

    deleteItem(i) {
      this.itemToDeleteIndex = i;
      this.dialogDelete = true;
    },

    handleCloseDelete() {
      this.itemToDeleteIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItemConfirm() {
      this.accordions.splice(this.itemToDeleteIndex, 1);

      this.files = this.accordions;
      this.handleChangeValue();
      this.handleCloseDelete();
    },

    handleEditInputData(index, item) {
      this.showAccordionInputs = true;

      if (this.editedItemIndex == null) {
        this.editedItemIndex = index;
        this.inputData = Object.assign({}, item);
      }
    },

    handleChangeValue() {
      this.$emit("handleChangeValue", this.accordions);
    },
  },
};
</script>
